/**
 * @description     \ Deal Builder middleware express routes
 * @baseURL         \ https://deal-builder-middleware
 */
import config from "../config";

/**
 * @method     \ GET
 * @param      \ int
 * @return     \ json
 */
const getVersions = async (ref) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/versions/versionIndex/${ref}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ GET
 * @param      \ int
 * @return     \ json
 */
const getContactBasket = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/baskets/contact/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getDeal = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/deals/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getDeals = async (page = 1) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: { page: page, read_all: true, pagination: true },
    endpoint: `/api/portal/index`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLiveProduct = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/liveProducts/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTeamMember = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/teamMembers/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLiveProductApprovedLog = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/logs/approvedLiveProduct/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getLiveProductLogs = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/logs/liveProduct/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getDealLogs = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/logs/deal/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ POST
 * @param      \ obj
 * @return     \ json
 */
const saveDeal = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ POST
 * @param      \ obj
 * @return     \ json
 */
const saveLiveProduct = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveTeamMember = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/teamMembers`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const basket = async () => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: {},
    endpoint: `/api/baskets/show`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getActiveDeals = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/activeIndex`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getArchivedDeals = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/archiveIndex`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const basketCheckout = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/contracts/sign`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveRequest = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/portal/requestChange/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getWorkingDeals = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/workingIndex`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResponsibleActiveDeals = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/responsibleActiveIndex`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResponsibleArchiveDeals = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/responsibleArchiveIndex`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResponsibleWorkingDeals = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/responsibleWorkingIndex`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ PUT
 * @param      \ int
 * @param      \ obj
 * @return     \ json
 */
const updateDeal = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ PUT
 * @param      \ int
 * @param      \ obj
 * @param      \ string OPTIONAL [ 'builder', 'config' ]
 * @return     \ json
 */
const updateLiveProduct = async (id, obj, type = "config") => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/update/${id}/${type}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ PUT
 * @param      \ int
 * @param      \ obj
 * @return     \ json
 */
const updateTeamMember = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/teamMembers/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const dealApprove = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/approve/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const dealComplete = async (id) => {
  const options = {
    base: "https://deal-builder-service",
    params: false,
    payload: null,
    endpoint: `/api/deals/complete/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const dealCancel = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/cancel/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const dealRetire = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/retire/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const dealPublishToggle = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/togglePublish/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const dealRequestChange = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/requestChange/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const liveProductRequestChange = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/requestChange/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const liveProductApprove = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/approve/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const liveProductCancel = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/cancel/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const liveProductSelectToggle = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/toggleSelected/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const liveProductDisplayToggle = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/toggleDisplayed/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const liveProductGetDocuments = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/documents/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const liveProductGetSchedules = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/schedules/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateVersion = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/versions/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateBasket = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/baskets/update/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const checkoutBasket = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/baskets/checkout/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const basketLinkProducts = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/baskets/linkProducts/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const basketUnlinkProducts = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/baskets/unlinkProducts/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getSalesOppDeals = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/deals/salesOppIndex/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getPortalDeal = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: {},
    endpoint: `/api/portal/getDeal/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateProduct = async (id, obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/portal/updateProduct/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTerms = async (id, obj) => {
  console.log("id ", id);
  console.log("obj ", obj);
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/liveProducts/schedules/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getProduct = async (id, obj) => {
  console.log("get prod id ", id);
  console.log("get prod obj ", obj);
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/portal/getProduct/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ DELETE
 * @param      \ int
 * @return     \ json
 */
const deleteDeal = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/deals/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ DELETE
 * @param      \ int
 * @return     \ json
 */
const deleteLiveProduct = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/liveProducts/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * @method     \ DELETE
 * @param      \ int
 * @return     \ json
 */
const deleteTeamMember = async (id) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: false,
    endpoint: `/api/teamMembers/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCategoryQueryParamsModel = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ecommerce/getCategoryQueryParamsModel`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getSupplierQueryParamsModel = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ecommerce/getSupplierQueryParamsModel`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCategoryTypes = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ecommerce/getCategoryTypes`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getDealByContract = async (id) => {
  const options = {
    base: "https://deal-builder-service",
    params: false,
    payload: {},
    endpoint: `/api/deals/getDealByContract/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getProvidersByCategory = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ecommerce/getProvidersByCategory`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getUnityProductAvailability = async (obj) => {
  const options = {
    base: "https://deal-builder-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/ecommerce/unityProductAvailability`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getProductsByContract = async (id) => {
  const options = {
    base: "https://deal-builder-service",
    params: false,
    payload: { id },
    endpoint: `/api/liveProducts/getProductsByContractId`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getVersions,
  getContactBasket,
  getDeal,
  getLiveProduct,
  getTeamMember,
  getLiveProductApprovedLog,
  getLiveProductLogs,
  getDealLogs,
  saveDeal,
  saveLiveProduct,
  saveTeamMember,
  basket,
  getActiveDeals,
  getArchivedDeals,
  getWorkingDeals,
  getResponsibleActiveDeals,
  getResponsibleArchiveDeals,
  getResponsibleWorkingDeals,
  updateDeal,
  updateLiveProduct,
  updateTeamMember,
  dealApprove,
  dealComplete,
  dealCancel,
  dealRetire,
  dealPublishToggle,
  dealRequestChange,
  liveProductRequestChange,
  liveProductApprove,
  liveProductCancel,
  liveProductSelectToggle,
  liveProductDisplayToggle,
  liveProductGetDocuments,
  liveProductGetSchedules,
  updateVersion,
  updateBasket,
  checkoutBasket,
  basketLinkProducts,
  basketUnlinkProducts,
  getSalesOppDeals,
  deleteDeal,
  deleteLiveProduct,
  deleteTeamMember,
  getDeals,
  getPortalDeal,
  basketCheckout,
  updateProduct,
  getProduct,
  getTerms,
  saveRequest,
  getCategoryQueryParamsModel,
  getSupplierQueryParamsModel,
  getCategoryTypes,
  getProvidersByCategory,
  getUnityProductAvailability,
  getDealByContract,
  getProductsByContract,
};
