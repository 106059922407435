/**
 * @description     \ Product middleware express routes
 * @baseURL         \ https://product-micro-middleware
 */
 import config from "../config";


 /**
  * @method     \ GET
  * @param      \ int
  * @return     \ json
  */
 const getStructure = async () => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/structure`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 /**
  * @method     \ GET
  * @param      \ int
  * @return     \ json
  */
 const getCategoryGroups = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/groups/categoryIndex/${id}`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 /**
  * @method     \ GET
  * @param      \ int
  * @return     \ json
  */
 const getGroupLines = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/lines/groupIndex/${id}`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getReviewProducts = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/reviewIndex`,
     method: "POST"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 const getArchivedProducts = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/archiveIndex`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getActiveProducts = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/activeIndex`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getWorkingProducts = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/workingIndex`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getTags = async () => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/tags`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 const getDocuments = async () => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/documents`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 const getSchedules = async () => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/schedules`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 const getGroup = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/groups/${id}`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getLine = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/lines/${id}`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getProduct = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/products/${id}`,
     method: "GET"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 const getTag = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/tags/${id}`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 const getVisibilities = async () => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/documents/visibility`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 const getVersions = async (ref) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/versions/versionIndex/${ref}`,
     method: "GET"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };

 
 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const saveGroup = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/groups`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const saveLine = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/lines`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const saveProduct = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const saveComponent = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/components`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const saveLabour = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/labourCosts`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const saveTag = async (obj) => {
    const options = {
      base: "https://product-micro-middleware",
      params: false,
      payload: obj,
      endpoint: `/api/tags`,
      method: "POST"
    }
  
    const data = await fetch(
      `${config.appURL}/api/proxy`,
      {
        credentials: "include",
        method: 'POST',
        body: JSON.stringify(options),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  
    const res = await data.json();
  
    return res;
  };


  const saveDocument = async (obj) => {
     const options = {
       base: "https://product-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/documents/add`,
       method: "POST"
     }
   
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
   
     const res = await data.json();
   
     return res;
   };


   const addDocuments = async (obj) => {
      const options = {
        base: "https://product-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/documents/link`,
        method: "POST"
      }
    
      const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
          credentials: "include",
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    
      const res = await data.json();
    
      return res;
    };


   const removeDocuments = async (obj) => {
      const options = {
        base: "https://product-micro-middleware",
        params: false,
        payload: obj,
        endpoint: `/api/documents/unlink`,
        method: "POST"
      }
    
      const data = await fetch(
        `${config.appURL}/api/proxy`,
        {
          credentials: "include",
          method: 'POST',
          body: JSON.stringify(options),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    
      const res = await data.json();
    
      return res;
    };


    const syncDocuments = async (obj) => {
       const options = {
         base: "https://product-micro-middleware",
         params: false,
         payload: obj,
         endpoint: `/api/documents/synchronise`,
         method: "POST"
       }
     
       const data = await fetch(
         `${config.appURL}/api/proxy`,
         {
           credentials: "include",
           method: 'POST',
           body: JSON.stringify(options),
           headers: {
             "Content-Type": "application/json",
             Accept: "application/json",
           },
         }
       );
     
       const res = await data.json();
     
       return res;
     };


     const saveSchedule = async (obj) => {
        const options = {
          base: "https://product-micro-middleware",
          params: false,
          payload: obj,
          endpoint: `/api/schedules/add`,
          method: "POST"
        }
      
        const data = await fetch(
          `${config.appURL}/api/proxy`,
          {
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(options),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      
        const res = await data.json();
      
        return res;
      };

 
   /**
    * @method     \ PUT
    * @param      \ int
    * @param      \ obj
    * @return     \ json
    */
   const updateGroup = async (id, obj) => {
     const options = {
       base: "https://product-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/groups/${id}`,
       method: "PUT"
     }
   
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
   
     const res = await data.json();
   
     return res;
   };

 
   /**
    * @method     \ PUT
    * @param      \ int
    * @param      \ obj
    * @return     \ json
    */
   const updateLine = async (id, obj) => {
     const options = {
       base: "https://product-micro-middleware",
       params: false,
       payload: obj,
       endpoint: `/api/lines/${id}`,
       method: "PUT"
     }
   
     const data = await fetch(
       `${config.appURL}/api/proxy`,
       {
         credentials: "include",
         method: 'POST',
         body: JSON.stringify(options),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
         },
       }
     );
   
     const res = await data.json();
   
     return res;
   };

 
 /**
  * @method     \ PUT
  * @param      \ int
  * @param      \ obj
  * @return     \ json
  */
 const updateProduct = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const updateGroupCommerce = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/groups/commerceSwitch`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 /**
  * @method     \ POST
  * @param      \ obj
  * @return     \ json
  */
 const updateLineCommerce = async (obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/lines/commerceSwitch`,
     method: "POST"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


/**
* @method     \ POST
* @param      \ obj
* @return     \ json
*/
const updateProductCommerce = async (obj) => {
 const options = {
   base: "https://product-micro-middleware",
   params: false,
   payload: obj,
   endpoint: `/api/products/commerceSwitch`,
   method: "POST"
 }

 const data = await fetch(
   `${config.appURL}/api/proxy`,
   {
     credentials: "include",
     method: 'POST',
     body: JSON.stringify(options),
     headers: {
       "Content-Type": "application/json",
       Accept: "application/json",
     },
   }
 );

 const res = await data.json();

 return res;
};


 const updateComponent = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/components/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const updateLabour = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/labourCosts/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ PUT
  * @param      \ int
  * @param      \ obj
  * @return     \ json
  */
 const updateTag = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/tags/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ PUT
  * @param      \ int
  * @param      \ obj
  * @return     \ json
  */
 const updateDocument = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ PUT
  * @param      \ int
  * @param      \ obj
  * @return     \ json
  */
 const updatePrimary = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/documents/primary/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };

 
 /**
  * @method     \ PUT
  * @param      \ int
  * @param      \ obj
  * @return     \ json
  */
 const productVersion = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/versions/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const productApproval = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/approve/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const productCancellation = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/cancel/${id}`,
     method: "PUT"
   }
  
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
  
   const res = await data.json();
  
   return res;
 };


 const productRejection = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/reject/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const productArchive = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/archive/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };


 const productRequest = async (id, obj) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: obj,
     endpoint: `/api/products/requestApproval/${id}`,
     method: "PUT"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteGroup = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/groups/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteLine = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/lines/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteProduct = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/products/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteComponent = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/components/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteLabour = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/labourCosts/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteSchedule = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/schedules/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 

 /**
  * @method     \ DELETE
  * @param      \ int
  * @return     \ json
  */
 const deleteTag = async (id) => {
   const options = {
     base: "https://product-micro-middleware",
     params: false,
     payload: false,
     endpoint: `/api/tags/${id}`,
     method: "DELETE"
   }
 
   const data = await fetch(
     `${config.appURL}/api/proxy`,
     {
       credentials: "include",
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     }
   );
 
   const res = await data.json();
 
   return res;
 };
 
 
 
 export {
   getStructure, 
   getCategoryGroups,
   getGroupLines,
   getReviewProducts,
   getActiveProducts,
   getArchivedProducts,
   getWorkingProducts,
   getTags,
   getDocuments,
   getSchedules,
   getGroup,
   getLine,
   getProduct,
   getTag,
   getVisibilities,
   getVersions,
   saveGroup,
   saveLine,
   saveProduct,
   saveComponent,
   saveLabour,
   saveTag,
   saveDocument,
   addDocuments,
   removeDocuments,
   syncDocuments,
   saveSchedule,
   updateGroup,
   updateLine,
   updateProduct,
   updateGroupCommerce,
   updateLineCommerce,
   updateProductCommerce,
   updateComponent,
   updateLabour,
   updateTag,
   updateDocument,
   updatePrimary,
   productVersion,
   productApproval,
   productCancellation,
   productRejection,
   productArchive,
   productRequest,
   deleteGroup,
   deleteLine,
   deleteProduct,
   deleteComponent,
   deleteLabour,
   deleteSchedule,
   deleteTag
 };
 