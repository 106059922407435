import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

/*
example data for form: {
  name: <value>: {
    type: <html input type>, 
    multiple: bool,
    options: [<string>],
    fullWidth: bool,
    label: string
  }
}
for a textfield, just have <fieldname>:{}
*For html input types see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
*/

export const DynamicFormItem = ({ name, value, data, setData }) => {
  const updateData = (name, value) => {
    setData(name, value);
  };

  return data?.type === "checkbox" ? (
    <FormControlLabel
      control={
        <Checkbox
          checked={(value && value[name]) || false}
          onChange={(e) => updateData(name, e.target.checked)}
        />
      }
      label={data?.label || name}
    />
  ) : Array.isArray(data?.options) ? (
    <SelectComp name={name} data={data} value={value} updateData={updateData} />
  ) : (
    <TextField
      name={name}
      label={data?.label || name}
      value={(value && value[name]) || ""}
      onChange={(e) => updateData(name, e.target.value)}
      type={data?.type || "text"}
      fullWidth={data?.fullWidth}
      size="small"
    />
  );
};

const SelectComp = ({ name, data, value, updateData }) => {
  const [width, setWidth] = useState(0);
  const selectRef = useRef(null);
  const hiddenRef = useRef(null);

  useEffect(() => {
    if (!data?.fullWidth && hiddenRef.current) {
      // Measure the width of the widest MenuItem
      const widths = Array.from(hiddenRef.current.children).map(
        (child) => child.offsetWidth
      );
      const maxWidth = Math.max(...widths);
      setWidth(maxWidth);
    }
  }, [data]);

  return (
    <>
      <FormControl
        fullWidth={data?.fullWidth}
        sx={!data?.fullWidth ? { minWidth: width, maxWidth: 500 } : {}}
        size="small"
      >
        <InputLabel id="formitem">{data?.label || name}</InputLabel>
        <Select
          size="small"
          ref={selectRef}
          sx={!data?.fullWidth ? { minWidth: width + 50, maxWidth: 500 } : {}}
          value={value && value[name] ? value[name] : data?.multiple ? [] : ""}
          onChange={(e) => updateData(name, e.target.value)}
          label={data?.label || name}
          multiple={data?.multiple}
          fullWidth={data?.fullWidth}
        >
          {data.options.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div
        style={{
          visibility: "hidden",
          position: "absolute",
          pointerEvents: "none",
        }}
        ref={hiddenRef}
      >
        <div style={{ display: "inline-block" }}>{data?.label || name}</div>
        {data.options.map((option, index) => (
          <div key={index} style={{ display: "inline-block" }}>
            {option}
          </div>
        ))}
      </div>
    </>
  );
};
