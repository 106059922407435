import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import config from "../config";

export const AddressAutocomplete = ({ setAddress }) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const getSuggestedAddresses = async () => {
      const res = await axios.post(
        `${config.appURL}/api/getAddress/autocomplete`,
        { searchterm: search }
      );
      console.log(res);
      if (res && res.data.success) setResults(res.data?.suggestions);
    };
    if (search && typeof search === "string" && search.length > 3) {
      getSuggestedAddresses();
    }
  }, [search]);

  useEffect(() => {
    const getAddress = async () => {
      const address = JSON.parse(selected);
      const res = await axios.post(`${config.appURL}/api/getAddress/get`, {
        id: address.id,
      });
      if (res && res.data.success && setAddress) setAddress(res.data.address);
    };
    if (selected) getAddress();
  }, [selected]);

  console.log(results);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        width: "100%",
        p: 1,
      }}
    >
      <TextField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        label="Enter address"
        sx={{ flex: 1 }}
      />
      {results ? (
        <FormControl sx={{ flex: 2 }}>
          <InputLabel id="select-address">Select address</InputLabel>
          <Select
            labelId="select-address"
            label="Select address"
            renderValue={(value) => {
              const val = JSON.parse(value);
              return val.address;
            }}
            value={selected || ""}
            onChange={(e) => setSelected(e.target.value)}
          >
            {results.map((el) => (
              <MenuItem key={el.id} value={JSON.stringify(el)}>
                {el.address}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </Box>
  );
};
